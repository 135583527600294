import React, { useMemo, useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

const useMouseActive = () => {
  const [
    isMouseActive,
    setMouseActive
  ] = useState(true)

  const moveHandler = useMemo(() => {
    let timeout = 0
    const throttled = throttle(e => {
      setMouseActive(true)
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setMouseActive(false)
      }, 2000)
    }, 250)
    return (e) => {
      return throttled(e)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', moveHandler)
    return () => {
      window.removeEventListener('mousemove', moveHandler)
    }
  }, [])

  return isMouseActive
}

export default useMouseActive
