import React from 'react'
import { Global } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'
import { GlobalContextProvider } from '../context/GlobalContext'
import useSettings from '../hooks/useSettings'
import Header from './Header'
import Footer from './Footer'
import './fonts.css'

const Layout = ({ data, pageContext, location, children }) => {
  const { colorTheme: defaultColorTheme, promoteWork } = useSettings()
  const isHome = location.pathname === '/'

  // @TEMP: get data from page context (when we have an works index as a homepage)
  let hasDocs = false
  if (data?.work?._rawMedia?.length > 0) {
    hasDocs = true
  }

  let bg
  let color

  if (Object.keys(pageContext).length !== 0 && !location.pathname.includes('/documentation')) { // Work page
    bg = pageContext.bg
    color = pageContext.color
  } else if (isHome) { // Home page
    bg = promoteWork?.colorTheme?.background?.hex || defaultColorTheme?.background?.hex
    color = promoteWork?.colorTheme?.text?.hex || defaultColorTheme?.text?.hex
  } else { // documentation, info, 404
    bg = defaultColorTheme?.background?.hex
    color = defaultColorTheme?.text?.hex
  }

	return <GlobalContextProvider value={{ location, pageContext, bg, color }}>
		<Global
			styles={`
        body {
          background-color: ${bg};
          color: ${color};
          transition: background linear 300ms;
        }
      `}
    />
		<Header
      bg={bg}
      color={color}
      isHome={isHome}
      // @TEMP: when we use a work on the homepage we don't have pageContext so pass the data we expect as props
      title={data?.work?.title || 'Back'}
      slug={data?.work?.path || '/'}
      hasDocs={hasDocs}
      {...pageContext}
    />
    <AnimatePresence mode='wait'>
  		{children}
    </AnimatePresence>
    <Footer bg={bg} color={color} isHome={isHome} />
	</GlobalContextProvider>
}

export default Layout
