import {graphql, useStaticQuery} from 'gatsby'

const useSettings = () => {
	const { settings } = useStaticQuery(
		graphql`
      query {
        settings: sanitySettings {
          title
          promoteWork {
            colorTheme {
              background {
                hex
              }
              text {
                hex
              }
            }
          }
          colorTheme {
            background {
              hex
            }
            text {
              hex
            }
          }
          cv {
            asset {
              url
            }
          }
          seo {
            title
            description
            image {
              asset {
                url
              }
            }
          }
          _rawContactContent(resolveReferences: {maxDepth: 10})
          _rawLegalContent(resolveReferences: {maxDepth: 10})
        }
      }
		`)
	return settings
}

export default useSettings
