import React, { useEffect, useReducer } from 'react'
import useMouseActive from '../hooks/useMouseActive'

const initialState = {
  showUI: true,
  played: 0
}

export function reducer(state, action) {
  switch (action.type) {
    case 'show':
      return { ...state, showUI: true }
    case 'hide':
      return { ...state, showUI: false }
    case 'played':
      return { ...state, played: action.value }
    default:
      return state
  }
}

export const GlobalContext = React.createContext({})

const GlobalContextProvider = ({ value, children }) => {

  const [state, dispatch] = useReducer(reducer, initialState)
  const isMouseActive = useMouseActive()

  function setShowUI() {
    dispatch({ type: 'show' })
  }

  function setHideUI() {
    dispatch({ type: 'hide' })
  }

  function setPlayed(value) {
    dispatch({ type: 'played', value })
  }

  useEffect(() => {
    if (isMouseActive) {
      setShowUI()
    } else {
      setHideUI()
    }
  }, [isMouseActive])

  return (
    <GlobalContext.Provider
      value={{
        color: value.color,
        showUI: state.showUI,
        played: state.played,
        setShowUI,
        setHideUI,
        setPlayed
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalContextProvider }
