import React, { useContext } from 'react'
import { Link, Stack, chakra } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { AnimatePresence, motion, isValidMotionProp } from 'framer-motion'
import { GlobalContext } from '../context/GlobalContext'
import useSettings from '../hooks/useSettings'

const MotionDiv = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const variants = {
  hide: {
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }
  },
  show: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }
  }
}

const Footer = ({ bg }) => {
  const { showUI } = useContext(GlobalContext)
  const { title } = useSettings()
  return <AnimatePresence>
    <Stack
      as={MotionDiv}
      initial='hide'
      animate={showUI ? 'show' : 'hide'}
      variants={variants}
      bg={{ base: bg, lg: 'transparent' }}
      textAlign={{ base: 'center', md: 'right' }}
      direction='column'
      spacing={0}
      zIndex={'banner'}
      position='fixed'
      bottom={0}
      right={0}
      left={0}
      py={{ base: 3, lg: 10 }}
      px={{ base: 5, lg: 10 }}
    >
      <Link as={GatsbyLink} to={'/info'} variant={'navLink'} fontSize={{ base: 'sm', md: 'lg' }}>{title}</Link>
    </Stack>
  </AnimatePresence>
}

export default Footer
