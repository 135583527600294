import React, { useContext } from 'react'
import { Flex, Link, chakra } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { AnimatePresence, motion, isValidMotionProp } from 'framer-motion'
import { GlobalContext } from '../context/GlobalContext'

const MotionDiv = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const variants = {
  hide: {
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }
  },
  show: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }
  }
}

const Header = ({ bg, isHome, title, hasDocs, slug }) => {
  const { showUI } = useContext(GlobalContext)

	return <AnimatePresence>
      <Flex
        as={MotionDiv}
        initial='hide'
        animate={showUI ? 'show' : 'hide'}
        variants={variants}
        bg={{ base: bg, lg: 'transparent' }}
        direction='row'
        justify={'space-between'}
        position={'fixed'}
        zIndex={'banner'}
        top={0}
        right={0}
        left={0}
        pt={{ base: 3, lg: 10 }}
        px={{ base: 5, lg: 10 }}
      >
      <Link display={'block'} as={GatsbyLink} activeClassName='active' variant={'navLink'} to={slug} fontSize={{ base: 'sm', md: 'lg' }}>{title}</Link>
      { hasDocs && <Link display={hasDocs ? 'block' : 'none'} as={GatsbyLink} activeClassName='active' variant={'navLink'} to={`${slug}/documentation`} fontSize={{ base: 'sm', sm: 'md', md: 'lg' }}>{'Documentation'}</Link>}
    </Flex>
  </AnimatePresence>
}

export default Header
