import { extendTheme, defineStyle, defineStyleConfig } from '@chakra-ui/react'



const breakpoints = {
  base: '0em',
  sm: '48em',
  md: '62em',
  lg: '80em',
  xl: '96em',
  '2xl': '140em'
}

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
}

const Heading = {
  baseStyle: {
    // color: 'dark',
    fontFamily: 'body',
    fontWeight: 400
  },
  variants: {
    uppercase: {
      textTransform: 'uppercase',
      letterSpacing: 'wide'
    }
  },
  defaultProps: {
    // size: 'lg'
  }
}

const underlinedLink = defineStyle({
  // color: 'dark',
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 400,
  textDecorationLine: 'underline',
  textUnderlineOffset: '0.125em',
  textDecorationColor: 'inherit',
  textDecorationThickness: '0.075em',
  _hover: {
    textDecorationColor: 'transparent',
    _disabled: {
      textDecorationColor: 'inherit',
    },
  },
  '&.active, &:active': {
    textDecorationColor: 'inherit'
  }
})

const textLink = defineStyle({
  // color: 'dark',
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 400,
  textDecorationLine: 'underline',
  textUnderlineOffset: '0.125em',
  textDecorationColor: 'transparent',
  textDecorationThickness: '0.075em',
  _hover: {
    textDecorationColor: 'inherit',
    _disabled: {
      textDecorationColor: 'transparent',
    },
  },
  '&.active, &:active': {
    textDecorationColor: 'inherit'
  }
})

const buttonTheme = defineStyleConfig({
  variants: {
    textLink,
    navLink: {
      ...textLink,
      letterSpacing: 'wider',
      // textTransform: 'lowercase'
    }
  }
})

const linkTheme = defineStyleConfig({
  variants: {
    underlinedLink,
    navLink: {
      ...textLink,
      letterSpacing: 'wider',
      // textTransform: 'lowercase'
    }
  },
})

const theme = {
  zIndices,
	styles: {
		global: {
      'a': {
        textDecorationLine: 'underline',
        textUnderlineOffset: '0.125em',
        textDecorationColor: 'transparent',
        textDecorationThickness: '0.075em',
        'a.active, :focus, :hover': {
          textDecorationColor: 'dark',
        }
      },
      html: {
        fontFamily: 'body',
      },
      'p + p': {
        marginTop: '1em',
      },
      'p:last-child':{
        marginBottom: '0 !important'
      },
      ul: {
        textAlign: 'left',
        listStyle: 'none',
        li: {
          fontSize: { base: 'lg', md: 'xl', lg: '2xl', 'xl': '3xl', '2xl': '4xl' }
        }
      }
		},
	},
	fonts: {
		body: `'Sackers Roman Heavy', serif`,
    light: `'Sackers Roman Light', serif`
	},
	colors: {
		white: '#FFFFFF',
		black: '#212121',
    light: '#E0E0E2',
    medium: '#B6B6B8',
    dark: '#212121',
		accent: '#0038FF',
	},
	fontSizes: {
		// 'lg': '18px'
	},
  breakpoints,
	components: {
		Container: {
			sizes: {
				full: {
					maxW: 'full',
					paddingX: '5.572rem' // from 60px
				}
			},
			defaultProps: {
				size: 'full'
			}
		},
    Heading,
    Link: linkTheme,
    Button: buttonTheme,
	}
}

export default extendTheme(theme)
